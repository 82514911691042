// BMRHesaplama.js

import React, { useState } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";

function BMRHesaplama() {
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("male");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [bmrResult, setBmrResult] = useState(null);
  const [error, setError] = useState(null);

  const calculateBMR = () => {
    if (!age || !weight || !height) {
      setError("Lütfen tüm alanları doldurun.");
      setBmrResult(null);
      return;
    }

    let bmr = 0;

    if (gender === "male") {
      bmr = 10 * weight + 6.25 * height - 5 * age + 5;
    } else if (gender === "female") {
      bmr = 10 * weight + 6.25 * height - 5 * age - 161;
    }

    setBmrResult(bmr.toFixed(2));
    setError(null);
  };

  return (
    <Container>
      <h1>BMR Hesaplama</h1>
      <Form>
        <Form.Group>
          <Form.Label>Yaş</Form.Label>
          <Form.Control
            type="number"
            placeholder="Yaşınızı girin"
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Cinsiyet</Form.Label>
          <Form.Control
            as="select"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="male">Erkek</option>
            <option value="female">Kadın</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Kilo (kg)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Kilonuzu girin"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Boy (cm)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Boyunuzu girin"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" onClick={calculateBMR}>
          Hesapla
        </Button>
      </Form>

      {error && <Alert variant="danger">{error}</Alert>}

      {bmrResult && (
        <div>
          <h2>Temel Metabolizma Hızınız (BMR): {bmrResult} kcal</h2>
        </div>
      )}
    </Container>
  );
}

export default BMRHesaplama;
