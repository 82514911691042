// VucutYagOraniHesaplama.js

import React, { useState } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";

function VucutYagOraniHesaplama() {
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("male");
  const [weight, setWeight] = useState("");
  const [heightInCm, setHeightInCm] = useState("");
  const [waist, setWaist] = useState("");
  const [hip, setHip] = useState("");
  const [vucutYagOraniResult, setVucutYagOraniResult] = useState(null);
  const [error, setError] = useState(null);
  const neck = 30;

  const calculateVucutYagOrani = () => {
    if (!age || !weight || !heightInCm || !waist || !hip) {
      setError("Lütfen tüm alanları doldurun.");
      setVucutYagOraniResult(null);
      return;
    }

    // Bel ve basen değerlerini kontrol et
    if (waist <= 0 || hip <= 0) {
      setError("Bel ve basen değerleri sıfır veya negatif olamaz.");
      setVucutYagOraniResult(null);
      return;
    }

    let bodyFatPercentage = 0;

    if (gender === "male") {
      bodyFatPercentage =
        86.010 * Math.log10(waist - neck) - 70.041 * Math.log10(heightInCm) + 36.76;
    } else if (gender === "female") {
      bodyFatPercentage =
        163.205 * Math.log10(waist + hip - neck) - 97.684 * Math.log10(heightInCm) - 78.387;
    }

    setVucutYagOraniResult(bodyFatPercentage.toFixed(2));
    setError(null);
  };

  return (
    <Container>
      <h1>Vücut Yağ Oranı Hesaplama</h1>
      <Form>
        <Form.Group>
          <Form.Label>Yaş</Form.Label>
          <Form.Control
            type="number"
            placeholder="Yaşınızı girin"
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Cinsiyet</Form.Label>
          <Form.Control
            as="select"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="male">Erkek</option>
            <option value="female">Kadın</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Kilo (kg)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Kilonuzu girin"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Boy (cm)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Boyunuzu girin"
            value={heightInCm}
            onChange={(e) => setHeightInCm(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Bel Çevresi (cm)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Bel çevresini girin"
            value={waist}
            onChange={(e) => setWaist(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Basen Çevresi (cm)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Basen çevresini girin"
            value={hip}
            onChange={(e) => setHip(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" onClick={calculateVucutYagOrani}>
          Hesapla
        </Button>
      </Form>

      {error && <Alert variant="danger">{error}</Alert>}

      {vucutYagOraniResult && (
        <div>
          <h2>Vücut Yağ Oranınız: {vucutYagOraniResult}%</h2>
        </div>
      )}
    </Container>
  );
}

export default VucutYagOraniHesaplama;
