// Kalori.js

import React, { useState } from "react";
import { Form, Button, Alert, Col, Container, Row, Card } from "react-bootstrap";

function Kalori() {
  // state variables for form inputs
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [activity, setActivity] = useState("");

  // state variable for form submission
  const [submitted, setSubmitted] = useState(false);

  // state variable for calculated calories
  const [calories, setCalories] = useState(null);

  // state variable for recommended calories
  const [recommendedCalories, setRecommendedCalories] = useState(null);

  // state variable for recommended macros
  const [recommendedMacros, setRecommendedMacros] = useState(null);

  // state variable for error
  const [error, setError] = useState(null);

  // function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    // Check if any field is empty
    if (!gender || !age || !height || !weight || !activity) {
      setError("Lütfen tüm alanları doldurun.");
      return;
    }

    // Calculate daily calorie needs and save to state
    const calculatedCalories = calculateCalories(gender, age, height, weight, activity);
    setCalories(calculatedCalories);

    // Calculate recommended calories for weight goals
    const weightLossCalories = (calculatedCalories * 0.8).toFixed(2);
    const weightMaintenanceCalories = calculatedCalories;
    const weightGainCalories = (calculatedCalories * 1.2).toFixed(2);

    setRecommendedCalories({
      loss: weightLossCalories,
      maintenance: weightMaintenanceCalories,
      gain: weightGainCalories,
    });

    // Calculate recommended macros based on weight goals
    const proteinRatio = 1.8; // grams of protein per kilogram of body weight
    const fatRatio = 0.3; // grams of fat per kilogram of body weight

    const recommendedProteinLoss = (weight * proteinRatio).toFixed(2);
    const recommendedCarbsLoss = ((weightLossCalories * 0.5) / 4).toFixed(2);
    const recommendedFatLoss = (weight * fatRatio).toFixed(2);

    const recommendedProteinMaintenance = (weight * proteinRatio).toFixed(2);
    const recommendedCarbsMaintenance = ((weightMaintenanceCalories * 0.5) / 4).toFixed(2);
    const recommendedFatMaintenance = (weight * fatRatio).toFixed(2);

    const recommendedProteinGain = (weight * proteinRatio).toFixed(2);
    const recommendedCarbsGain = ((weightGainCalories * 0.5) / 4).toFixed(2);
    const recommendedFatGain = (weight * fatRatio).toFixed(2);

    setRecommendedMacros({
      loss: { protein: recommendedProteinLoss, carbs: recommendedCarbsLoss, fat: recommendedFatLoss },
      maintenance: { protein: recommendedProteinMaintenance, carbs: recommendedCarbsMaintenance, fat: recommendedFatMaintenance },
      gain: { protein: recommendedProteinGain, carbs: recommendedCarbsGain, fat: recommendedFatGain },
    });

    setError(null);
  };

  // function to handle form reset
  const handleReset = () => {
    setGender("");
    setAge("");
    setHeight("");
    setWeight("");
    setActivity("");
    setSubmitted(false);
    setCalories(null); // Reset calculated calories
    setRecommendedCalories(null); // Reset recommended calories
    setRecommendedMacros(null); // Reset recommended macros
    setError(null);
  };

  // function to calculate daily calorie needs
  function calculateCalories(gender, age, height, weight, activity) {
    let basalMetabolicRate = 0;

    if (gender === "male") {
      basalMetabolicRate =
        88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
    } else if (gender === "female") {
      basalMetabolicRate =
        447.593 + 9.247 * weight + 3.098 * height - 4.330 * age;
    }

    let dailyCalorieNeeds = 0;

    switch (activity) {
      case "sedentary":
        dailyCalorieNeeds = basalMetabolicRate * 1.2;
        break;
      case "light":
        dailyCalorieNeeds = basalMetabolicRate * 1.375;
        break;
      case "moderate":
        dailyCalorieNeeds = basalMetabolicRate * 1.55;
        break;
      case "high":
        dailyCalorieNeeds = basalMetabolicRate * 1.725;
        break;
      default:
        break;
    }

    return dailyCalorieNeeds.toFixed(2);
  }

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <div className="Kalori">
            <h1 className="mb-4">Günlük Kalori İhtiyacı Hesaplama</h1>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="gender">
                <Form.Label>Cinsiyet</Form.Label>
                <Form.Check
                  type="radio"
                  name="gender"
                  label="Erkek"
                  value="male"
                  checked={gender === "male"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <Form.Check
                  type="radio"
                  name="gender"
                  label="Kadın"
                  value="female"
                  checked={gender === "female"}
                  onChange={(e) => setGender(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="age">
                <Form.Label>Yaş</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Yaşınızı girin"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="height">
                <Form.Label>Boy</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Boyunuzu cm cinsinden girin"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="weight">
                <Form.Label>Kilo</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Kilonuzu kg cinsinden girin"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="activity">
                <Form.Label>Aktivite Seviyesi</Form.Label>
                <Form.Control
                  as="select"
                  value={activity}
                  onChange={(e) => setActivity(e.target.value)}
                >
                  <option value="">Seçiniz</option>
                  <option value="sedentary">Hareketsiz</option>
                  <option value="light">Biraz Aktif</option>
                  <option value="moderate">Ortalama Aktif</option>
                  <option value="high">Yüksek Aktif</option>
                </Form.Control>
              </Form.Group>
              <Button type="submit" variant="primary" className="mr-2">
                Hesapla
              </Button>
              <Button type="button" variant="secondary" onClick={handleReset}>
                Sıfırla
              </Button>
            </Form>

            {error && (
              <Alert variant="danger" className="mt-3">
                {error}
              </Alert>
            )}

            {submitted && !error && (
              <Alert variant="success" className="mt-3">
                Form başarıyla gönderildi. Günlük kalori ihtiyacınız: {calories} kalori.
              </Alert>
            )}

            {recommendedCalories && (
              <div className="mt-3">
                <h5>Önerilen Günlük Kalori Miktarı:</h5>
                <Row>
                  <Col md={4}>
                    <Card bg="success" text="white" className="mb-2">
                      <Card.Body>
                        <Card.Title>Kilo Vermek İçin</Card.Title>
                        <Card.Text>{recommendedCalories.loss} kalori</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card bg="primary" text="white" className="mb-2">
                      <Card.Body>
                        <Card.Title>Kilo Korumak İçin</Card.Title>
                        <Card.Text>{recommendedCalories.maintenance} kalori</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card bg="danger" text="white" className="mb-2">
                      <Card.Body>
                        <Card.Title>Kilo Almak İçin</Card.Title>
                        <Card.Text>{recommendedCalories.gain} kalori</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}

            {recommendedMacros && (
              <div className="mt-3">
                <h5>Önerilen Günlük Makro Miktarları:</h5>
                <Row>
                  <Col md={4}>
                    <Card bg="info" text="white" className="mb-2">
                      <Card.Body>
                        <Card.Title>Kilo Vermek İçin</Card.Title>
                        <Card.Text>
                          Protein: {recommendedMacros.loss.protein}g
                          <br />
                          Karbonhidrat: {recommendedMacros.loss.carbs}g
                          <br />
                          Yağ: {recommendedMacros.loss.fat}g
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card bg="warning" text="white" className="mb-2">
                      <Card.Body>
                        <Card.Title>Kilo Korumak İçin</Card.Title>
                        <Card.Text>
                          Protein: {recommendedMacros.maintenance.protein}g
                          <br />
                          Karbonhidrat: {recommendedMacros.maintenance.carbs}g
                          <br />
                          Yağ: {recommendedMacros.maintenance.fat}g
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card bg="danger" text="white" className="mb-2">
                      <Card.Body>
                        <Card.Title>Kilo Almak İçin</Card.Title>
                        <Card.Text>
                          Protein: {recommendedMacros.gain.protein}g
                          <br />
                          Karbonhidrat: {recommendedMacros.gain.carbs}g
                          <br />
                          Yağ: {recommendedMacros.gain.fat}g
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Alert variant="info" className="mt-3">
                  <p>
                    Not: Bu önerilen makro miktarları genel beslenme prensiplerine dayanmaktadır.
                    Bireysel ihtiyaçlarınız farklılık gösterebilir. Sağlıklı bir diyet planı
                    oluşturmak için bir beslenme uzmanına danışmanız önerilir.
                  </p>
                </Alert>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Kalori;
