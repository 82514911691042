// App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Container, Navbar, Nav, Card, Row, Col } from "react-bootstrap";
import Kalori from "./Kalori";
import BMRHesaplama from "./BMRHesaplama";
import VucutYagOraniHesaplama from "./VucutYagOraniHesaplama";
import BMIHesaplama from "./BMIHesaplama"; // BMI bileşenini ekledik

function App() {
  return (
    <Router>
      <Navbar bg="light" variant="light">
        <Container>
          <Navbar.Brand as={Link} to="/">
            Fit App
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/kalori" className="d-none d-lg-block">
              Günlük Kalori Hesaplama
            </Nav.Link>
            <Nav.Link as={Link} to="/bmr" className="d-none d-lg-block">
              BMR Hesaplama
            </Nav.Link>
            <Nav.Link as={Link} to="/vucut-yag-orani" className="d-none d-lg-block">
              Vücut Yağ Oranı Hesaplama
            </Nav.Link>
            <Nav.Link as={Link} to="/bmi" className="d-none d-lg-block"> {/* BMI Hesaplama ekledik */}
              BMI Hesaplama
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/kalori" element={<Kalori />} />
        <Route path="/bmr" element={<BMRHesaplama />} />
        <Route path="/vucut-yag-orani" element={<VucutYagOraniHesaplama />} />
        <Route path="/bmi" element={<BMIHesaplama />} /> {/* BMI Hesaplama ekledik */}
      </Routes>
    </Router>
  );
}

function Home() {
  return (
    <Container className="mt-3">
      <h1>Fit App</h1>
      <Row>
        <Col md={6}>
          <Card bg="info" text="white" className="mb-3">
            <Card.Body>
              <Card.Title>Günlük Kalori Hesaplama</Card.Title>
              <Card.Text>
                Hesapla ve hedeflerine uygun günlük kalori ihtiyacını öğren.
              </Card.Text>
              <Nav.Link as={Link} to="/kalori" className="stretched-link">
                Hemen Hesapla
              </Nav.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card bg="success" text="white" className="mb-3">
            <Card.Body>
              <Card.Title>BMR Hesaplama</Card.Title>
              <Card.Text>
                Bazal Metabolizma Hızını hesapla ve temel enerji ihtiyacını öğren.
              </Card.Text>
              <Nav.Link as={Link} to="/bmr" className="stretched-link">
                Hemen Hesapla
              </Nav.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card bg="warning" text="white" className="mb-3">
            <Card.Body>
              <Card.Title>Vücut Yağ Oranı Hesaplama</Card.Title>
              <Card.Text>
                Vücut yağ oranını hesapla ve sağlıklı bir vücut kompozisyonu hedefle.
              </Card.Text>
              <Nav.Link as={Link} to="/vucut-yag-orani" className="stretched-link">
                Hemen Hesapla
              </Nav.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card bg="primary" text="white" className="mb-3">
            <Card.Body>
              <Card.Title>BMI Hesaplama</Card.Title>
              <Card.Text>
                Vücut kitle indeksini hesapla ve sağlıklı kilo yönetimine başla.
              </Card.Text>
              <Nav.Link as={Link} to="/bmi" className="stretched-link">
                Hemen Hesapla
              </Nav.Link>
            </Card.Body>
          </Card>
        </Col>
        <p>
                  Not: Bütün öneriler genel beslenme prensiplerine dayanmaktadır.
                  Bireysel ihtiyaçlarınız farklılık gösterebilir. Sağlıklı bir diyet planı
                  oluşturmak için bir beslenme uzmanına danışmanız önerilir.
                </p>
      </Row>
    </Container>
  );
}

export default App;
