import React, { useState } from "react";
import { Form, Button, Alert, Col, Container, Row, Card } from "react-bootstrap";
// ... (Diğer import'lar)

function BMIHesaplama() {
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("male");
  const [bmi, setBMI] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null); // Yeni hata durumu

  const getBMIClassification = (bmi) => {
    if (bmi < 18.5) {
      return "Zayıf";
    } else if (bmi >= 18.5 && bmi < 25) {
      return "Normal";
    } else if (bmi >= 25 && bmi < 30) {
      return "Fazla Kilolu";
    } else {
      return "Aşırı Kilolu";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Boş alanları kontrol et ve uyarı göster
    if (!height || !weight) {
      setError("Lütfen boy ve kilo alanlarını doldurun.");
      setSubmitted(false);
      return;
    }

    setSubmitted(true);

    const calculatedBMI = calculateBMI(height, weight, gender);
    setBMI(calculatedBMI);

    setError(null); // Hata durumunu sıfırla
  };

  const calculateBMI = (height, weight, gender) => {
    const heightInMeters = height / 100;
    const bmiValue = weight / (heightInMeters * heightInMeters);

    if (gender === "male") {
      return bmiValue * 1.1;
    } else if (gender === "female") {
      return bmiValue * 0.9;
    } else {
      return bmiValue;
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <Card className="p-4 mt-4">
            <h1 className="mb-4">Boy Kilo Endeksi (BMI) Hesaplama</h1>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="height">
                <Form.Label>Boy</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Boyunuzu cm cinsinden girin"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="weight">
                <Form.Label>Kilo</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Kilonuzu kg cinsinden girin"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="gender">
                <Form.Label>Cinsiyet</Form.Label>
                <Form.Control
                  as="select"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="male">Erkek</option>
                  <option value="female">Kadın</option>
                </Form.Control>
              </Form.Group>
              <Button type="submit" variant="primary">
                Hesapla
              </Button>
            </Form>

            {error && (
              <Alert variant="danger" className="mt-3">
                {error}
              </Alert>
            )}

            {submitted && !error && (
              <div>
                <Alert variant="success" className="mt-3">
                  BMI başarıyla hesaplandı. BMI: {bmi}
                </Alert>

                <Card className="mt-3">
                  <Card.Body>
                    <Card.Title>Vücut Kitle İndeksi (BMI) Sınıflandırması</Card.Title>
                    <Card.Text>
                      <strong>Sınıflandırma: </strong>
                      {getBMIClassification(bmi)}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default BMIHesaplama;
